<template>
	<div class="int-content clearfix">
		<!--订单已经提交成功-->
		<div class="success-bg mt20">
			<div>
				<p class="icon-suc iconfont icon-gouxuan01 suc-text icon-chenggong" v-if="isSuccess">{{ resultTxt }}</p>
				<p class="icon-fail suc-text  iconfont icon-errorfill" v-else>{{ resultTxt }}</p>
				<p class="detail-text">
					<i class="left-title">订单号：</i>
					<a class="order-number" @click="bindJumpOrderDetail" href="javascript:;">{{ OrderDetailInfo.SOShortNr }}</a>
				</p>
				<p class="detail-text">
					<i class="left-title">订单金额：</i>
					<span class="red fb">￥{{ OrderDetailInfo.OrderTotalAmount }}</span>
				</p>
				<p class="detail-text text2" v-if="isSuccess">
					<i class="left-title">结算方式：</i>
					<span class="red mr20">{{ payTypeTxt }}</span>
				</p>
				<div class="suc-loading-box" v-if="isCountdown">
					<div class="suc-loading"><img :src="require('@/assets/suc_loading.gif')" /></div>
					<div class="loading-text ft18">
						<i class="red">{{ countDownNum }}</i>
						秒后完成
					</div>
					<p class="ft18">正在努力给您订座中，请稍等…</p>
				</div>
				<block v-else>
					<button class="pay-order" v-if="OrderDetailInfo.IsPayable">马上支付</button>
					<p class="suc-btn-box">
						<a href="javascript:;" @click="bindBooker" class="detail-btn mr20 ml20" >继续预定</a>
						<a href="javascript:;" class="detail-btn ml20" @click="bindJumpOrderDetail">查看详情</a>
					</p>
				</block>
			</div>			
		</div>
	</div>
</template>

<script>
import { getAccountInfo, getBookingFormInfo, setBookingFormInfo } from '@/utils/localdatas';

import {getOrderDetailByID} from '@/api/order'

var util = require('@/utils/util.js');
var that;
export default {
	data() {
		return {
			title: '订单结果页',
			userId: '',
			orderno: '',
			OrderDetailInfo: '',
			//订单详情数据
			payTypeID: 1,
			countDownNum: 10,
			countDownDone: false,
			countDownTxt: '',
			isSuccess: true,
			resultTxt: '订单提交成功',
			payTypeTxt: '',
			isCountdown: false
		};
	},
	created() {
		that = this;
		var query = that.$route.query;
		console.log(query);
		console.log(query.id);
		that.orderno = query.id;
		
		let payTypeID = query.payTypeID;
		let payTypeTxt = '现付，请尽快支付';

		if (payTypeID == 2) {
			payTypeTxt = '月结，系统正在扣款出票中...';
		} else if (payTypeID == 3) {
			payTypeTxt = '预付，系统正在扣现金账户余额出票中...';
		}
		that.payTypeTxt = payTypeTxt;
		getOrderDetail(that.orderno);
		// getPassengerList();
	},
	methods: {
		bindBooker(e){
			that.$router.push({ path: '/Insurance'});
		},
		//
		bindJumpIndex(){
			that.$router.push({ path: '/'});
		},
		//
		bindJumpOrderDetail(){
			that.$router.push({ path: '/Profile/OrderDetail', query: { id: that.orderno} });
		},
		//
		bindGetPay(){
			
		}
		
		
	}
};

//获取数据
function getOrderDetail(id) {
	// uni.showLoading({
	// 	title: '加载中'
	// });
	let Accountinfo = getAccountInfo();
	let parm = {
		Account: Accountinfo.Account,
		id: id
	};
	getOrderDetailByID( parm, function(result) {
		if (result.code == 0) {
			//console.log(result.data.OrderDetailInfo);
			let OrderDetailInfo = result.data.OrderDetailInfo;
			console.log(OrderDetailInfo);
			that.OrderDetailInfo = OrderDetailInfo;
			that.isSuccess = true;
			that.resultTxt = '预定成功'
			// OrderDetailInfo.PNR = "";

			// if (OrderDetailInfo.PNR == null || OrderDetailInfo.PNR == '') {
			// 	if (that.countDownDone == false) {
			// 		that.isCountdown = true;

			// 		setcountDown();
			// 	} else {
			// 		console.log(111);
			// 		that.resultTxt = '订单订座失败';
			// 		that.isSuccess = false;
			// 	}
			// } else {
			// 	(that.isSuccess = true), (that.countDownDone = true), (that.resultTxt = '订座成功');
			// }
			//写入统计

			// try {
			// 	var trackPostData = {
			// 		BrowseOrder: {
			// 			BookerID: OrderDetailInfo.BookerID,
			// 			SOShortNr: OrderDetailInfo.SOShortNr,
			// 			TotalAmount: OrderDetailInfo.OrderTotalAmount,
			// 			CurrencyID: 4,
			// 			OrderDate: OrderDetailInfo.OrderDate
			// 		}
			// 	};
			// 	util.getYqfTrack(trackPostData);
			// } catch (e) {}
		} else {
			alert(result.msg);
			// uni.showModal({
			// 	title: '提示',
			// 	content: result.msg,
			// 	showCancel: false,
			// 	success: function(res) {
			// 		if (res.confirm) {
			// 			uni.reLaunch({
			// 				url: '/pages/member/orderlist/orderlist'
			// 			});
			// 		}
			// 	}
			// });
		}
	});
}

//倒计时
function setcountDown() {
	let num = that.countDownNum;
	let timer = setInterval(function() {
		num--;

		if (num > 0) {
			that.countDownNum = num;
			that.countDownTxt = num + 's';
		} else {
			clearInterval(timer);
			that.countDownNum = 15;
			that.isCountdown = false;
			that.countDownDone = true;
			getOrderDetail(that.orderno);
		}
	}, 1000);
}
</script>

<style></style>
